
    import bubbleAnimation from '../../assets/json/bubble-scoding.json';
    import bubblesAnimation from '../../assets/json/bubbles-scoding.json';
    import mouseAnimation from '../../assets/json/mouse.json';

    export default {
        name: 'HeadingSection',
        components: {
            CustomLottiePlayer: () => import('./CustomLottiePlayer.vue'),
        },
        props: {
            backgroundImage: {
                type: String,
                default: String,
            },
            template: {
                type: String,
                default: String,
            },
            heading: {
                type: String,
                default: String,
            },
            description: {
                type: String,
                default: String,
            },
            redButtonText: {
                type: String,
                default: String,
            },
            greyButtonText: {
                type: String,
                default: String,
            },
            headerPageId: {
                type: Number,
                default: Number,
            },
            slug: {
                type: String,
                default: String,
            },
            secondarySlug: {
                type: String,
                default: String,
            },
            projectClient: {
                type: String,
                default: String,
            },
            projectUrl: {
                type: String,
                default: String,
            },
            projectType: {
                type: String,
                default: String,
            },
            projectYear: {
                type: String,
                default: String,
            },
            marginTop: {
                type: Number,
                default: Number,
            },
        },
        data() {
            return {
                bubbleAnimation,
                bubblesAnimation,
                mouseAnimation,
            };
        },
        methods: {
            redirectToPage(slug, secondarySlug) {
                if (slug && secondarySlug) {
                    this.$router.push(`/${slug}/${secondarySlug}`);
                } else {
                    this.$router.push(`/${slug}`);
                }
            },
            scrollToContactForm() {
                document.getElementById('contact-information').scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'nearest',
                    },
                );
            },
        },
    };
